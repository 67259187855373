<template>
    <div v-if="isPermission && isEditData">
        <CCard class="shadow-sm m-2">
            <CCardBody>
                <CRow>
                    <CCol sm="1" col="2">
                        <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
                    </CCol>
                    <CCol sm="9" col="8">
                        <select id="AddOptionRatioShopSelect" class="custom-select text-dark" v-model="shop" disabled>
                            <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                                {{ shop.shopName }} - {{ shop.branchName }}
                            </option>
                        </select>
                        <div v-if="validateShop" class="text-danger">
                            {{ validateShop }}
                        </div>
                    </CCol>
                    <div class="col-md-2 col-sm-2  col-2 text-right">
                        <CButton id="AddOptionRatioBackButton" block color="light" @click="getback()">
                            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                        </CButton>
                    </div>
                </CRow>
            </CCardBody>
        </CCard>
        <CCol md="12" lg="12" class="mt-4">
            <CCard class="shadow-sm">
                <CCardBody>
                    <CAlert id="AddOptionRatioSuccessAlert" color="success" v-if="isSuccess === true">
                        {{ $t('createProductSuccess') }}
                    </CAlert>
                    <div class="row">
                        <div class="col-md-10 col-sm-9 col-9">
                            <h4 class="font-weight-normal text-dark">{{ $t('addSKUratio') }}</h4>
                            <h4 class="font-weight-normal text-dark">
                                <span class="h4">
                                    {{ $t('txtOption') }} : {{ this.name }}
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-2 col-sm-3  col-3 text-right">
                            <CButton id="AddOptionRatioSaveButton" block color="success" @click="confirmSaveProductBOM">
                                {{ $t('save') }}
                            </CButton>
                        </div>
                    </div>
                    <hr />

                    <CRow>
                        <CCol sm="6" lg="6" md="6">
                            <CCard class="cardadd">
                                <CCardBody>
                                    <div>
                                        <CInput id="AddOptionRatioSKUInput" v-model.trim="keyword" :placeholder="$t('searchByProduct')"
                                            @input="getSKUbyType()" />
                                    </div>
                                    <table id="AddOptionRatioSKUTable" class="table table-hover" style="width: 100%">
                                        <tbody>
                                            <div class="overflow-auto" style="max-height: 350px">
                                                <tr :id="'AddOptionRatioSKUItem'+ index" v-for="(item, index) in items" :key="index" hover border
                                                    class="cursor" striped @click="addBOMtoLits(item, index)">

                                                    <td :id="'AddOptionRatioSKUItemData'+ index">
                                                <tr>
                                                    <span class="text-dark font-weight-normal">
                                                        {{ item.SKUName }}
                                                    </span>
                                                </tr>
                                                <tr>
                                                    <span class="text-dark font-weight-normal">{{
                                                        item.productPLU.PLUCode }}
                                                    </span>
                                                </tr>
                                                <tr>
                                                    <small class="text-dark font-weight-normal">
                                                        {{ item.unit.name }} / {{ item.SKURatio }}
                                                    </small>
                                                </tr>
                                                </td>

                                                <td style="width: 2%;" class="text-right text-dark">
                                                    <i class="fi fi-rr-angle-small-right"
                                                        @click="addBOMtoLits(item, index)" :id="'AddOptionRatioSKUItemIcon'+ index"></i>
                                                </td>
                                                </tr>
                                            </div>
                                        </tbody>
                                    </table>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol sm="6" lg="6" md="6">
                            <CCard class="cardadd">
                                <CCardHeader>
                                    <CRow>
                                        <CCol md="9" sm="9">
                                            <h5 class="font-weight-normal">
                                                {{ $t('addSKUratio') }} ({{ countAddList }})
                                            </h5>
                                        </CCol>
                                        <CCol md="3" sm="3" class="text-right"> </CCol>
                                    </CRow>
                                </CCardHeader>

                                <CCardBody class="mt-0 pt-0">
                                    <table class="table table-hover" id="AddOptionRatioSelectedListTable" style="width: 100%">

                                        <thead>
                                            <tr>
                                                <!-- <th>
                                                    {{ $t('product') }}
                                                </th> -->
                                                <th style="width: 90%" colspan="2" class="text-right pr-5" >
                                                    {{  $t('SKURatio') }}
                                                </th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div v-if="loadingButton == true" class="overflow-auto"
                                                style="min-height: 300px; max-height: 300px">
                                                <tr :id="'AddOptionRatioSelectedListItem' +index " v-for="(item, index) in addlist" :key="index" hover border striped>
                                                    <td>
                                                        <tr>
                                                            <span class="font-weight-normal text-dark">
                                                                {{ item.SKUName }}
                                                            </span>
                                                        </tr>
                                                        <tr>
                                                            <span class="font-weight-normal text-dark">
                                                                {{ item.productPLU.PLUCode }}
                                                            </span>
                                                        </tr>
                                                        <tr>
                                                            <small class="font-weight-normal text-dark">
                                                                {{ item.unit.name }} / {{ item.SKURatio }}
                                                            </small>
                                                        </tr>
                                                </td>
                                                <td style="width: 20%" class="text-center">
                                                    <input :id="'AddOptionRatioSelectedListItemRatio'+ index"  type="number" min="0" v-model.number="item.ratio"
                                                        class="form-control" />
                                                </td>
                                                <td style="width: 5%" class="text-center">
                                                    <img :id="'AddOptionRatioSelectedListItemRemoveIcon'+ index" src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; "   @click="conremoveRow(item, index)">
                                                </td>
                                                </tr>
                                            </div>

                                            <div v-else class="text-center overflow-auto" width="100%"
                                                style="min-height: 300px; max-height: 300px">
                                                <button id="AddOptionRatioLoadingButton" class="btn " type="button" disabled style="color: darkcyan;">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                    {{ $t('loading') }}
                                                </button>
                                            </div>
                                        </tbody>
                                    </table>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
        <modal id="AddOptionRatioConfirmModal" color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" v-on:isSave="saveProductRatio"
            centered :loading="loading">
            <div>
                <h4 class="mt-4 mb-4 text-center">{{ $t('confirmSaveProduct') }}</h4>
            </div>
        </modal>
    </div>
    <div v-else>
        <access-data></access-data>
    </div>
</template>

<script>
import util from '@/util/util'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'

export default {

    data() {
        return {
            loading: true,
            loadingButton: true,
            isSuccess: false,
            SKUName: '',
            keyword: '',
            data: [],
            BOM: [],
            addlist: [],
            dataSKUbytype: [],
            validateShop: false,
            quantity: 1,
            countAddList: 0,
            shop: this.$store.getters.shopObjectId,
            confirmModal: false,
            name: '',
            objectId: '',
            optionItem: {},
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date']),
        isPermission() {
            let path = '/product'
            return permis.findPermissionRead('product', path)
        },
        isEditData() {
            let path = '/product'
            return permis.findPermissionEdit('product', path)
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            return shop.uid
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        items() {
            let detail = []
            let data = this.dataSKUbytype
            for (let i = 0; i < data.length; i++) {
                let SKURatio = ''
                if (data[i].ProductPLU.SKURatio != undefined) {
                    SKURatio = data[i].ProductPLU.SKURatio
                }
                let unit = []
                if (data[i].unit == null) {
                    unit = '-'
                } else {
                    if (data[i].unit.name == null) {
                        unit = '-'
                    } else {
                        unit = data[i].unit
                    }
                }
                detail.push({
                    // remoteImagePath: data[i].remoteImagePath,
                    SKUName: data[i].ProductPLU.name,
                    unit: unit,
                    id: data[i].id,
                    objectId: data[i].objectId,
                    productPLU: data[i].ProductPLU,
                    SKURatio: SKURatio,
                    stdCost: data[i].stdCost,
                    // colori: util.generateColor(data[i].indexColor),
                })
            }
            return detail
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    created() {
        this.getOptionItem()
        this.getOptionItemProduct()
        this.getSKUbyType()
        this.shop = this.shops.find((i) => i.objectId === this.shop)
    },
    mounted() {
        document.addEventListener('keydown', this.doSave)
    },
    methods: {
        doSave(e) {
            if (!(e.keyCode === 83 && e.ctrlKey)) {
                return
            } else if (e.keyCode === 83 && e.ctrlKey) {
                this.confirmSaveProductBOM()
            }
            e.preventDefault()
        },
        getOptionItem(page = 1) {
            this.searchLoadingButton = false
            this.loadingButton = false
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            const objectId = this.$route.params.objectId

            const params = { page: page, limit: 50, shopObjectId: shopObjectId, objectId: objectId }
            const headers = { shopObjectId: shopObjectId }
            local({
                url: '/api/V1.0/' + uid + '/option/item/' + objectId,
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                const data = res.data.data
                this.data = data
                this.name = data.name
                this.price = data.price
                this.itemObjectId = data.objectId
                this.id = data.id
                this.searchLoadingButton = true
                this.loadingButton = true
            })
        },
        getOptionItemProduct() {
            const uid = `${localStorage.getItem('shopsUid')}`
            let params = {
                objectId: this.$route.params.objectId
            }
            const headers = {
                shopObjectId: this.shopObjectId,
            }
            local({
                url: '/api/v1.0/' + uid + '/option/item/product',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                this.BOM = res.data.data
                this.BOM.forEach((e) => {
                    let SKURatio = ''
                    if (e.productPLU.SKURatio != undefined) {
                        SKURatio = e.productPLU.SKURatio
                    }
                    let productPLU = e.productPLU
                    this.addlist.push({
                        productPLU: e.productPLU,
                        PLUid: e.productPLU.id,
                        PLUCode: e.productPLU.PLUCode,
                        SKUName: e.productPLU.name,
                        productPLUObjectId: e.productPLU.objectId,
                        optionObjectId: e.optionItem.objectId,
                        optionId: e.optionItem.id,
                        optionName: e.optionItem.name,
                        unit: e.productPLU.unit || '-',
                        SKURatio: SKURatio,
                        ratio: e.ratio,
                        uid: e.uid,
                        objectId: e.objectId,
                        id: e.id

                    })

                })
                this.optionItem = this.BOM[0].optionItem; // ให้ให้ที่ index 0 ได้เพื่อตัวอย่าง
                this.countAddList = this.addlist.length
            })
        },
        getSKUbyType() {
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let params = {
                shopObjectId: shopObjectId,
                skuType: 'P',
                keyword: this.keyword,
                page: 1,
                limit: 50,
            }
            const headers = {
                shopObjectId: this.shopObjectId,
            }
            local({
                url: '/api/v1.0/' + uid + '/productsku/getbytype',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                this.dataSKUbytype = res.data.data
                this.addlist.forEach((e) => {
                    let data = res.data.data
                    const index = data.findIndex(
                        (x) => x.ProductPLU.objectId === e.productPLUObjectId
                    )
                    if (index != -1) {
                        this.dataSKUbytype.splice(index, 1)
                    }
                })
            })
        },
        addBOMtoLits(item, index) {
            this.addlist.push({
                productPLU: item.productPLU,
                // id: item.id,
                SKUName: item.SKUName,
                productPLUObjectId: item.productPLU.objectId,
                unit: item.productPLU.unit,
                unit: {
                    id: item.unit.id,
                    name: item.unit.name,
                    objectId: item.unit.objectId,
                },
                SKURatio: item.SKURatio,
                stdCost: item.stdCost,
                optionItem: {
                    objectId: this.optionItem.objectId,
                    id: this.optionItem.id,
                    name: this.optionItem.name,
                },

            })
            this.items.splice(index, 1)
            this.countAddList = this.addlist.length
        },
        conremoveRow: function (item, index) {
            var con = confirm(this.$i18n.t('confirmSaveProduct'))
            if (con == true) {
                this.addlist.splice(index, 1)
                this.items.push(item)
                this.countAddList = this.addlist.length
                this.removeRow(item)
            }
        },
        removeRow: function (item) {
            const uid = `${localStorage.getItem('shopsUid')}`

            let data = [{
                objectId: item.objectId,
                id: item.id,
                optionItem: {
                    objectId: item.optionObjectId,
                    id: item.optionId,
                    name: item.optionName,
                },
                productPLU: {
                    id: item.productPLU.id,
                    PLUCode: item.productPLU.PLUCode,
                    name: item.productPLU.name,
                    objectId: item.productPLU.objectId,
                    SKURatio: item.productPLU.SKURatio,
                    unit: item.productPLU.unit,
                },
                ratio: item.ratio,
                uid: uid,
                deleted_at: new Date(),
                shop: {
                    objectId: this.shop.objectId,
                    id: this.shop.id,
                    branchName: this.shop.branchName,
                },
            }]
            const headers = {
                shopObjectId: this.shopObjectId,
            }
            local({
                method: 'post',
                url: '/api/V1.0/' + uid + '/option/product/update',
                data: data,
                headers: headers,
            })
                .then(
                    setTimeout(() => {
                        this.countAddList = this.addlist.length
                    }, 1000)
                )
                .catch(function (error) {
                    console.log(error)
                })
        },
        confirmSaveProductBOM() {
            this.loading = true
            this.confirmModal = true
        },
        saveProductRatio() {
            const uid = `${localStorage.getItem('shopsUid')}`;
            let data = this.addlist;
            const objectId = this.$route.params.objectId;
            let dataitem = [];
            this.loading = false;
            data.forEach((e) => {
                // const isDuplicate = this.BOM.some(bomItem => {
                //     return bomItem.productPLU.objectId === e.productPLU.objectId && bomItem.ratio === e.ratio;
                // });
                // if (!isDuplicate) {
                    let item = {
                        optionItem: {
                            objectId: this.itemObjectId,
                            id: this.id,
                            name: this.name,
                        },
                        productPLU: {
                            id: e.productPLU.id,
                            PLUCode: e.productPLU.PLUCode,
                            name: e.productPLU.name,
                            objectId: e.productPLU.objectId,
                            SKURatio: e.productPLU.SKURatio,
                            unit: e.productPLU.unit,
                        },
                        ratio: e.ratio,
                        uid: uid,
                        shop: {
                            objectId: this.shop.objectId,
                            id: this.shop.id,
                            branchName: this.shop.branchName,
                        },
                    };
                    // เพิ่มเงื่อนไขเช็คว่ามี e.objectId และ e.id หรือไม่
                    if (e.objectId && e.id) {
                        item.objectId = e.objectId;
                        item.id = e.id;
                    }
                    dataitem.push(item);
                // }
            });
            const headers = {
                shopObjectId: this.shopObjectId,
            };
            local({
                method: 'post',
                url: '/api/V1.0/' + uid + '/option/product/update',
                data: dataitem,
                headers: headers,
            })
                .then(() => {
                    setTimeout(() => {
                        this.loading = true;
                        this.confirmModal = false;
                        this.$router.go(-1);
                    }, 1000);
                })
                .catch(function (error) {
                    console.error(error);
                });
        },

        // saveProductRatio() {
        //     const uid = `${localStorage.getItem('shopsUid')}`
        //     let data = this.addlist
        //     const objectId = this.$route.params.objectId
        //     let dataitem = []
        //     this.loading = false
        //     console.log(data)
        //     data.forEach((e) => {
        //         const isDuplicate = this.BOM.some(bomItem => {
        //             return bomItem.productPLU.objectId === e.productPLU.objectId && bomItem.ratio === e.ratio;
        //         });
        //         if (!isDuplicate) {
        //             dataitem.push({
        //                 objectId: e.objectId,
        //                 id: e.id,
        //                 optionItem: {
        //                     objectId: this.itemObjectId,
        //                     id: this.id,
        //                     name: this.name,
        //                 },
        //                 productPLU: {
        //                     id: e.productPLU.id,
        //                     PLUCode: e.productPLU.PLUCode,
        //                     name: e.productPLU.name,
        //                     objectId: e.productPLU.objectId,
        //                     SKURatio: e.productPLU.SKURatio,
        //                     unit: e.productPLU.unit,
        //                 },
        //                 ratio: e.ratio,
        //                 uid: uid,
        //                 shop: {
        //                     objectId: this.shop.objectId,
        //                     id: this.shop.id,
        //                     branchName: this.shop.branchName,
        //                 },
        //             })
        //         }

        //     })
        //     const headers = {
        //         shopObjectId: this.shopObjectId,
        //     }
        //     local({
        //         method: 'post',
        //         url: '/api/V1.0/' + uid + '/option/product/update',
        //         data: dataitem,
        //         headers: headers,
        //     })
        //         .then(() => {
        //             setTimeout(() => {
        //                 this.loading = true
        //                 this.confirmModal = false
        //                 this.$router.go(-1)
        //             }, 1000)
        //         })
        //         .catch(function (error) {
        //             console.error(error);
        //         })
        // },

        getback() {
            this.$router.go(-1)
        },
    },
}
</script>

<style>
.cardadd {
    min-height: 438px;
    max-height: 400px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
}

.form-control {
    cursor: pointer;
}

.custom-select {
    cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
    cursor: default;
}

.cursor {
    cursor: pointer;
}
</style>